import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PricingPage = () => (
  <Layout title="Simple Credit-Based Pricing">
    <SEO title="Pricing" />
    <p>pakete</p>
    <p>
      vorteile auflisten: kein server, super fast, perfect documentation,
      privacy, clients, <br />
      Beispielrechnungen: Smart Home, Notfall, Business, Enterprise TTS,
      Preview, Cache
    </p>
  </Layout>
)

export default PricingPage
